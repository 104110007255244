import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { RestClientApi } from '@runway/devkit-common';

export enum ServiceAccountRole {
  MEMBER = 'Member',
}

export type ServiceAccount = {
  name: string;
  slug: string;
  description: string;
  role: ServiceAccountRole;
  key: string;
  teams: string[];
  created_by: string;
  created_by_url: string;
  created_at: string;
  key_expires_at: string;
};
export interface CloudsmithServiceApi {
  getAllServiceAccounts(options?: { page: number; pageSize: number }): Promise<ServiceAccount[]>;
  createServiceAccount(serviceAccount: { name: string; description: string }): Promise<ServiceAccount>;
}

export const cloudsmithServiceApiRef = createApiRef<CloudsmithServiceApi>({
  id: 'plugin.infrastructure.cloudsmith',
});

export default class CloudsmithService implements CloudsmithServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  getAllServiceAccounts = async (options?: { page: number; pageSize: number }) => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get<ServiceAccount[]>(
      `${this.backendUrl}/api/cloudsmith/service-accounts${options ? `?page=${options.page}&pageSize=${options.pageSize}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Failed to get data');
    }

    return res.data;
  };

  createServiceAccount = async (serviceAccount: { name: string; description: string }) => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.post<ServiceAccount>(
      `${this.backendUrl}/api/cloudsmith/service-accounts`,
      serviceAccount,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Failed to get data.');
    }

    return res.data;
  };
}
