import { Grid, FormControl } from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import FormContext from '../FormContext';
import { ObjectLike, SquadDropdownProps } from '../types';
import { componentGridStyle } from '../styles';
import { SquadInfo } from '../../../components/SquadInfo/SquadInfo';

/**
 * Use this component to capture the squad name from the user.
 * @param name your schema field name
 * @param onBlur your blur setting for the picker.
 * @param size optional size of the component
 * @param onlyShowMySquads optional flag to show only the user's squads
 */
export function FormSquadName<T extends ObjectLike>({
  name,
  onBlur,
  size = 4,
  onlyShowMySquads = false,
}: SquadDropdownProps<T>) {
  const { ctx } = useContext(FormContext);
  return (
    <Grid item xs={12} sm={12} md={size} style={componentGridStyle}>
      <FormControl aria-label="form-squadname" variant="outlined" fullWidth required>
        <Controller
          name={name}
          control={ctx.control}
          render={({ field: { onChange } }) => (
            <SquadInfo
              onChange={value => onChange(value?.name)}
              onBlur={onBlur}
              onlyShowMySquads={onlyShowMySquads}
              required
              defaultValue={false}
              width="100%"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
}
