import { IdentityApi, ProfileInfo, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert, Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useAsync } from 'react-use';
import { Squad360ServiceApiRef, SquadIdName } from '../../services/squad360.service';

interface SquadInfoProps {
  onChange(value: SquadIdName | null): void;
  onBlur?: () => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  onlyShowMySquads?: boolean | undefined;
  initialValue?: any;
  defaultValue?: boolean;
  width?: string | number | undefined;
}

const SquadInfoAlerts = () => {
  return (
    <Alert style={{ marginTop: 10 }} severity="error">
      <strong>
        There was an issue getting the Squad Info.{' '}
        <a href="https://squad360-ui.cloud.aa.com/" target="_blank">
          Click <u>Squad360 to assign a squad to your AAID</u>
        </a>
      </strong>
    </Alert>
  );
};

export const getAAId = async (identityApi: IdentityApi) => {
  const aaProfile = (await identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
  if (!aaProfile) return '-1';
  const employeeId = aaProfile.aaId?.toString() ?? '-1';
  if (employeeId.length === 8) {
    return employeeId.substring(2);
  }
  return employeeId;
};

export const getAAInfo = async (identityApi: IdentityApi) => {
  const aaProfile = (await identityApi.getProfileInfo()) as ProfileInfo & { aaId: string };
  if (!aaProfile) return undefined;
  const employeeId = aaProfile.aaId?.toString() ?? '-1';
  aaProfile.aaId = employeeId.length === 8 ? employeeId.substring(2) : employeeId.toString();
  return aaProfile;
};

export const SquadInfo: React.FC<SquadInfoProps> = ({
  onChange,
  onBlur,
  disabled = false,
  required = false,
  onlyShowMySquads = false,
  initialValue = undefined,
  defaultValue = true,
  width = 300,
}: SquadInfoProps) => {
  const identityApi = useApi(identityApiRef);
  const squad360 = useApi(Squad360ServiceApiRef);

  const squadOptions = useAsync(async () => {
    const aaId = await getAAId(identityApi);
    const employeeSquads: SquadIdName[] = [];
    // try to fetch a list of employee specific squads
    try {
      const employeeInfo = await squad360.getSquadsByAAId(aaId);
      if (onlyShowMySquads) {
        return employeeInfo.squads;
      }
      employeeSquads.push(...employeeInfo.squads);
    } catch (error) {
      // only throw the error if onlyShowMySquads is true
      // otherwise fall back on the full squad list as usual
      if (onlyShowMySquads) throw error;
    }

    const allSquads = await squad360.getAllSquads();

    const finalSquads = [
      ...employeeSquads,

      // filter out overlap from employee squad
      ...allSquads.items.filter(squad => !employeeSquads.some(employeeSquad => employeeSquad.name === squad.name)),
    ];

    if (required && finalSquads[0]) {
      onChange(finalSquads[0]);
    }

    return finalSquads;
  }, [squad360]);

  const onSelect = (_: any, value: SquadIdName | null) => {
    onChange(value || null);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {squadOptions.value && squadOptions.value?.length > 0 && (
          <Autocomplete
            disabled={squadOptions.value.length === 0 || disabled}
            id="squadInfo"
            data-testid="squadInfo"
            disableClearable
            autoSelect
            defaultValue={
              ((initialValue as SquadIdName) ?? (required && defaultValue)) ? squadOptions.value[0] : undefined
            }
            options={squadOptions.value}
            getOptionLabel={option => `${option.id} - ${option.name}`}
            onChange={onSelect}
            onBlur={onBlur}
            style={{ width: width, display: 'inline-flex' }}
            renderInput={params => <TextField required={required} {...params} label="Squad Name" variant="outlined" />}
          />
        )}
        {(squadOptions.loading || squadOptions.error || squadOptions.value?.length === 0) && (
          <Autocomplete
            options={[]}
            style={{ width: width, display: 'inline-flex' }}
            renderInput={params => <TextField required={required} {...params} label="Squad Name" variant="outlined" />}
          />
        )}
        {squadOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
        {squadOptions.error && (
          <>
            <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
            <FormHelperText>There was an error getting the squad info</FormHelperText>
          </>
        )}
      </div>
      <div>
        {squadOptions.value?.length === 0 && !squadOptions.error && !squadOptions.loading && <SquadInfoAlerts />}
      </div>
    </>
  );
};
